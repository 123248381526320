import classes from './StudentResultQuestion.module.css';
import { HiOutlineTrophy } from 'react-icons/hi2';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked, MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { database } from '../../util/firebase';
import { ref, get, child } from 'firebase/database';
import ReviewImageCanvas from '../UI/ReviewImageCanvas';
import ReviewTapTheWordInput from '../UI/ReviewTapTheWordInput';
import ReviewDragDropAnchorSelector from '../UI/ReviewDragDropAnchorSelector';

const StudentResultQuestion = (props) => {
    /* console.log(props.questionData) */

    const [correctAnswers, setCorrectAnswers] = useState({});
    useEffect(() => {
        if(!props.expanded) return;
        if(!props.showCorrect) return;
        if(Object.keys(correctAnswers)?.length > 0) return;

        get(child(ref(database), `answers/${props.questionData.key}`))
            .then((snapshot) => {
                if (snapshot.exists()) { 
                    setCorrectAnswers(snapshot.val());
                }
        })

    }, [props, correctAnswers])

    const [globalStats, setGlobalStats] = useState({});
    useEffect(() => {
        if(!props.showStats) return;
        if(Object.keys(globalStats)?.length > 0) return;

        get(child(ref(database), `global_stats/${props.questionData.stimuliKey}/${props.questionData.key}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setGlobalStats(snapshot.val());
                }
        })

    }, [props, globalStats])

    const getStat = (answer_id) => {
        if(!globalStats.total_attempts || globalStats.total_attempts < 10) return ''
        else {
            if(globalStats[answer_id]) {
                return `${Math.floor(globalStats[answer_id] / globalStats.total_attempts * 100)}%`
            } else {
                return `0%`;
            }
        }
    }

    const getMatchedAnchor = (test_key) => {
        if(!props.questionData?.selected_answers?.anchor_answer_pairs) return null;

        for(const key in props.questionData.selected_answers.anchor_answer_pairs) {
            if(props.questionData.selected_answers.anchor_answer_pairs[key] === test_key) return key;
        }

        return null;
    }

    return(
        <div className={ `${classes.questionWrapper} ${props.last ? classes.last : ''}` }>
            <div className={ `${classes.question} ${ props.expanded ? classes.autoHeight : '' }` }>
                <div className={classes.flexRow}>
                    {props.questionData.is_capstone && 
                        <div className={classes.iconSpace}>
                        <HiOutlineTrophy />
                        </div>    
                    }
                    <div className={`${classes.questionText} ${props.questionData.is_capstone ? classes.hasIcon : ''}`}>
                        {
                            props.showStats && globalStats &&
                            <>
                                <div className={classes.percentBubble}>
                                    {(globalStats.total_attempts < 10 || !globalStats.total_attempts) ? 'New' : `${Math.floor(globalStats.correct_count / globalStats.total_attempts * 100)}%`}
                                </div>&nbsp;
                            </>
                        }
                        {
                            props.showLocalStats && props.questionData.local_stats &&
                            <>
                                <div className={classes.percentBubble}>
                                    {`${Math.floor((props.questionData.local_stats.correct_count || 0) / props.questionData.local_stats.total_attempts * 100)}%`}
                                </div>&nbsp;
                            </>
                        }
                        Q:&nbsp;{props.questionData.prompt}
                    </div>
                </div>
                {
                    props.questionData.answers && props.expanded &&
                    <div className={ `${classes.answers}` }>
                        {
                            props.questionData.type === 'multiple_choice' && Object.keys(props.questionData.answers)
                                .map(key => key !== 'percent_selected' && <div className={classes.answerRow} key={key}>
                                    { (props.showStats && getStat(key) !== '') ?
                                        <div className={classes.globalStat}>{getStat(key)}</div> : ''
                                    }
                                    {
                                        props.showLocalStats && props.questionData.local_stats &&
                                            <div className={classes.globalStat}>
                                                {`${Math.floor((props.questionData.local_stats[key] || 0) / props.questionData.local_stats.total_attempts * 100)}%`}
                                            </div>
                                    }
                                    <div className={classes.icon}>
                                        <span style={{ color: correctAnswers[key]?.is_correct ? 'green' : props.questionData.selected_answers.answer_ids && Object.keys(props.questionData.selected_answers.answer_ids).includes(key) ? 'red' : 'unset' }}>
                                            {
                                                props.questionData.selected_answers.answer_ids && Object.keys(props.questionData.selected_answers.answer_ids).includes(key) ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />
                                            }
                                        </span>
                                    </div>
                                    <div className={classes.answerText}>
                                    <span style={{ color: correctAnswers[key]?.is_correct ? 'green' : props.questionData.selected_answers.answer_ids && Object.keys(props.questionData.selected_answers.answer_ids).includes(key) ? 'red' : 'unset' }}>{props.questionData.answers[key]}</span>
                                    </div>
                                </div>)
                        }
                        {
                            props.questionData.type === 'multi_select' && Object.keys(props.questionData.answers)
                                .map(key => <div className={classes.answerRow} key={key}>
                                        { (props.showStats && getStat(key) !== '') ?
                                            <div className={classes.globalStat}>{getStat(key)}</div> : ''
                                        }
                                        {
                                            props.showLocalStats && props.questionData.local_stats &&
                                                <div className={classes.globalStat}>
                                                    {`${Math.floor((props.questionData.local_stats[key] || 0) / props.questionData.local_stats.total_attempts * 100)}%`}
                                                </div>
                                        }
                                        <div className={classes.icon}>
                                            <span style={{ color: correctAnswers[key]?.is_correct ? 'green' : props.questionData.selected_answers.answer_ids && Object.keys(props.questionData.selected_answers.answer_ids).includes(key) ? 'red' : 'unset' }}>
                                                {
                                                    props.questionData.selected_answers.answer_ids && Object.keys(props.questionData.selected_answers.answer_ids).includes(key) ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />
                                                }
                                            </span>
                                        </div>
                                        <div className={classes.answerText}>
                                            <span style={{ color: correctAnswers[key]?.is_correct ? 'green' : props.questionData.selected_answers.answer_ids && Object.keys(props.questionData.selected_answers.answer_ids).includes(key) ? 'red' : 'unset' }}>{props.questionData.answers[key]}</span>
                                        </div>
                                    </div>)
                        }
                        { props.questionData.type === 'text_entry' &&
                            <div className={classes.textAnswers}>
                                <span style={{ color: props.questionData.selected_answers.is_correct ? 'green' : 'red' }}>
                                    "{props.questionData.selected_answers.answer_text}"
                                </span>
                            </div>
                        }
                        { props.questionData.type === 'short_constructed_response' && 
                            <div className={classes.rubricAnswers}>
                                <span style={{ color: props.questionData.selected_answers.is_correct ? 'green' : 'red' }}>
                                    "{props.questionData.selected_answers.answer_text_long}"
                                </span>
                            </div>
                        }
                        { props.questionData.type === 'hot_spot' && props.showCorrect && Object.keys(correctAnswers).length > 0 &&
                            <div className={classes.hotSpotAnswers}>
                                <strong>Answer Regions:</strong>
                                <ReviewImageCanvas imagesrc={props.questionData?.image || '#'} initialAnswers={Object.keys(props.questionData?.answers)?.map(key => ({ rect: props.questionData?.answers[key], isCorrect: correctAnswers ? correctAnswers[key]?.is_correct : false, isSelected: props.questionData.selected_answers?.answer_ids ? props.questionData.selected_answers?.answer_ids[key] : false }))} static onChangeAnswerRegions={null} onError={null} />
                            </div>
                        }
                        { props.questionData.type === 'tap_the_word' && props.showCorrect && Object.keys(correctAnswers).length > 0 &&
                            <div className={classes.tapTheWordAnswers}>
                                <strong>Answers:</strong>
                                <ReviewTapTheWordInput stimuliText={props.questionData.stimulus_text} initialAnswers={Object.keys(props.questionData.answers)?.map(key => ({ region: props.questionData.answers[key], isCorrect: correctAnswers[key]?.is_correct, isSelected: props.questionData.selected_answers.answer_ids[key] || false}))} static onChangeAnswerRanges={null} onError={null} />
                            </div>
                        }
                        { props.questionData.type === 'drag_and_drop' &&
                            <>
                                <ReviewDragDropAnchorSelector imagesrc={props.questionData.image} initialAnchors={props.questionData.anchors} static onChangeAnchors={null} onError={null} />
                                <div className={classes.label}><strong>Draggable Answers{ props.showCorrect ? ' & Matching Anchors' : '' }:</strong></div>
                                {console.log('question data: ', props.questionData)}
                                {console.log('correct answers: ', correctAnswers)}
                                {
                                    Object.keys(props.questionData.answers).map((key, index) => 
                                        <div className={`${classes.anchorRow} ${index === Object.keys(props.questionData.answers)?.length - 1 ? classes.last : ''}`} key={key}>
                                            {props.showCorrect && Object.keys(props.questionData.anchors).filter(secondaryKey => props.questionData.selected_answers.anchor_answer_pairs[secondaryKey] === key).map(secondaryKey => <div key={secondaryKey} className={classes.anchorLabel}>{props.questionData.anchors[secondaryKey].display_num}</div>)}
                                            <div className={`${classes.anchorText} ${(correctAnswers[key]?.matching_anchors[getMatchedAnchor(key)] === true) ? classes.correctDragDrop : classes.incorrectDragDrop}`}>{props.questionData.answers[key]}</div>
                                        </div>
                                )}
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default StudentResultQuestion;