import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useLocation  } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, database } from '../util/firebase';
import { set, ref, get, child } from 'firebase/database';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';
import HorizontalLine from '../components/UI/HorizontalLine';
import TextBlock from '../components/UI/TextBlock';
import MultiselectInput from '../components/UI/MultiselectInput';
import StandaloneError from '../components/UI/StandaloneError';
import CheckboxInput from '../components/UI/CheckboxInput';

// TODO: Require first and last name, then display feedback for various form or authentication errors.
const SignupPage = () => {
    const [setHeaderSize] = useOutletContext();
    useEffect(() => {setHeaderSize('medium')}, [setHeaderSize]);
    
    const navigate = useNavigate();
    const location = useLocation();
    const navigateToLoginHandler = () => {
        navigate('/login');
    };

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [identityLink, setIdentityLink] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [identityLinkError, setidentityLinkError] = useState('');
    const [standardsError, setStandardsError] = useState('')
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const [termsError, setTermsError] = useState('');
    const [promoCode, setPromoCode] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('promo')
        if(code === 'CAST24') {
            setPromoCode(code)
            setIdentityLink(code)
        }
    }, [location]);

    const signupHandler = (event) => {
        event.preventDefault();

        var errorCounter = 0;
        if(firstName === '') {
            errorCounter++;
            setFirstNameError('This field is required.');
        };
        if(lastName === '') {
            errorCounter++
            setLastNameError('This field is required.');
        }
        if(email === '') {
            errorCounter++;
            setEmailError('This field is required.');
        };
        if(password === '') {
            errorCounter++
            setPasswordError('This field is required.');
        }
        if(identityLink === '') {
            errorCounter++
            setidentityLinkError('This field is required.');
        }
        if(selectedSets?.length === 0) {
            errorCounter++;
            setStandardsError('You must select at least one standard set.');
        }
        if(!hasAcceptedTerms) {
            errorCounter++;
            setTermsError('You must agree to the terms of service.');
        }
        if(errorCounter > 0) {return;}

        setIsSigningUp(true);
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // TODO: Change this date back to 30 days after Region 4
                const trial_expiry_date = new Date();
                if(promoCode === 'CAST24') trial_expiry_date.setDate(trial_expiry_date.getDate() + 90);
                else trial_expiry_date.setDate(trial_expiry_date.getDate() + 30);

                const userData = { first_name: firstName, last_name: lastName, email: email, roles: { unverified_teacher: true }, standards_environment: makeNewStandardsObject(), identity_link: identityLink, trial_expiry: trial_expiry_date.toISOString() };
                set(ref(database, `users/${userCredential.user.uid}`), userData).then(() => {
                    return set(ref(database, `user_accounts/${userCredential.user.uid}`), { first_name: firstName, last_name: lastName, email: email, verification_status: 'awaiting_verification', standards_environment: makeNewStandardsObject(), trial_expiry: trial_expiry_date.toISOString() })
                }).then(() => {
                    return sendEmailVerification(userCredential.user)
                }).then(() => {
                    console.log('Verification email sent.');
                    navigate('/assessments');
                }).catch((error) => {
                    console.error('Error sending verification email:', error);
                    // TODO: Do we want more error handling here?
                });
            }).catch((error) => {
                setIsSigningUp(false);
                if(error.message === 'Firebase: Error (auth/invalid-email).') {setEmailError('Invalid email address.'); setPasswordError('');};
                if(error.message === 'Firebase: Error (auth/email-already-in-use).') {setEmailError('Email address already in use.'); setPasswordError('');};
                if(error.message === 'Firebase: Password should be at least 6 characters (auth/weak-password).') {setPasswordError('Password should be at least 6 characters.'); setEmailError('');};
            });
    };

    const [standardSets, setStandardSets] = useState([]);
    const [selectedSets, setSelectedSets] = useState([]);
    useEffect(() => {
        let loadedSets = [];

        get(child(ref(database), `standards`)).then((snapshot) => {
            if(snapshot.exists()) {
                for(const key in snapshot.val()) {
                    for(const secondaryKey in snapshot.val()[key]) {
                        loadedSets.push({ value: `${key}/${secondaryKey}`, text: `${key} - ${secondaryKey}`})
                    }
                };

                setStandardSets(loadedSets);
            };
        });
    }, []);

    const standardSetModifiedHandler = (new_selections) => {
        setSelectedSets(new_selections);
        if(new_selections?.length !== 0) setStandardsError('')
    };

    const makeNewStandardsObject = () => {
        let object = {}

        for(let i = 0; i < selectedSets.length; i++) {
            const pieces = selectedSets[i].split('/');
            
            if(!object[pieces[0]]) object[pieces[0]] = {};
            object[pieces[0]][pieces[1]] = true;
        }

        return object
    }

    return (
        <SingleColumn>
            <div>
                <PageHeaderText>Teacher Sign Up</PageHeaderText>
                <form>
                    <TextInput name='user-first-name' value={ firstName } placeholder='First Name' leftIcon='person' error={ firstNameError } onChange={ (value) => {setFirstName(value); setFirstNameError('');} } half left />
                    <TextInput name='user-last-name' value={ lastName } placeholder='Last Name' error={ lastNameError } onChange={ (value) => {setLastName(value); setLastNameError('');} } half right />
                    <TextInput name='user-email' value={ email } placeholder='School Email' leftIcon='mail' error={ emailError } onChange={ (value) => {setEmail(value); setEmailError('');} } />
                    <TextInput name='user-password' value={ password } placeholder='Password' leftIcon='lock' rightIcon='eye' error={ passwordError } onChange={ (value) => {setPassword(value); setPasswordError('');} } password />
                    <PageHeaderText small labelFor='standard-set'>Which standard(s) would you like to see?</PageHeaderText>
                    {/* <TextBlock nospace>Teach more than one subject? Select multiple standard sets by holding the CTRL key.</TextBlock> */}
                    <MultiselectInput name='standard-set' options={ standardSets } onModified={standardSetModifiedHandler} selectedValues={selectedSets} short nospace/>
                    <StandaloneError spaceafter>{standardsError}</StandaloneError>
                    <div style={{textAlign: 'right', color: '#5C60F5', cursor: 'pointer', marginBottom: '22px'}}><span onClick={() => {navigate('/join-email-list')}}>Don't see your subject?</span></div>
                    { (!promoCode || promoCode !== 'CAST24') &&
                        <>
                            <TextBlock>Finally, please leave a link to your school (or district) website or directory so that we can verify that you are an educator. We verify each user by hand to ensure only educators have access to our assessment items. Please allow up to 24 hours for initial verification (but we often complete this step much faster).</TextBlock>
                            <TextInput name='identity-link' value={ identityLink} placeholder='Link' leftIcon='web' error={ identityLinkError } onChange={ (value) => {setIdentityLink(value); setidentityLinkError('');}} />
                        </>
                    }
                    <CheckboxInput checked={hasAcceptedTerms} onChange={() => {setHasAcceptedTerms(val => !val); setTermsError('');}} >By signing up, you acknowledge that you have read and accepted our <a href="/terms-of-service" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</CheckboxInput>
                    <StandaloneError>{termsError}</StandaloneError>
                    <br />
                    { promoCode && promoCode === 'CAST24' && 
                        <>
                            <br />
                            <span style={{fontWeight: "700", color: "Blue", fontSize: "16px"}}>CAST '24 Conference Special:</span> <span style={{fontSize: "16px"}}>Sign up today and get a 90-day free trial! (regularly 30 days)</span>
                            <br />
                        </>
                    }
                    <Button confirm onClick ={ signupHandler } disabled={ isSigningUp }>{ isSigningUp ? 'Creating Account...' : `Start ${promoCode === 'CAST24' ? '90' : '30'}-Day Free Trial` }</Button>
                </form>
                <HorizontalLine>or</HorizontalLine>
                <Button altblue onClick={ navigateToLoginHandler } spaceAfter>Log In</Button>
                <br />
                <br />
                <br />
                <br />
            </div>
        </SingleColumn>
    );
};

export default SignupPage;